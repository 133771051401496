.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.container-h2 {
  padding: 5px;
}
.add-button{
  background-color: #3c4858!important;
  color: #fff!important;
  box-shadow: none!important;
  font-family: "Fredoka One"!important;
  font-size: 13px!important;
  border: 1px solid #3c4858!important;
  height: 7vh;
}

.button-container {
  padding-bottom: 15px;
}

.inner-button-container {
  align-items: center;
  justify-content: center;
  display: flex;
}
h2 {
  font-family: "Raleway",sans-serif!important;
}
.freeDoka {
  font-family: "Fredoka One"!important;
  font-weight: 400;
}
.logo-image {
  width: 100px;
}
.App-header {
  background-color: #fff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
